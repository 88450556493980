<template>
  <div id="personalAwardIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.keyword" show-action placeholder="请输入关键词查询" @search="onSearch">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index)  in data" :key="index">
        <van-cell>
          <template #title>
            <div class="custom-title hidden-text">{{row.awardName}}</div>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text95">
              <svg-icon icon-class="date" size="15" /> 获奖日期: {{parseTime(row.awardAt, "{y}-{m}-{d}")}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="unit" size="15" /> 颁发单位: {{row.awardDept}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <div v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>
            <div v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>
            <div v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>
            <div v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </div>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="stuDisableBtn(row.approvalStatus,'edit')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="danger">删除</van-button>
            <van-button class="table-edit-btn" :disabled="stuDisableBtn(row.approvalStatus,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 提交 -->
    <div class="submit-button shadow-style">
      <van-button round block type="primary" @click="doAdd">新 增</van-button>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import initData from "@/mixins/initData";
import { del } from "@/api/modules/student/personalAward";

export default {
  name: "StuPersonalAwardIndex",
  mixins: [initData],
  dicts: ["xj_shzt"],
  data() {
    return {
      value: "",
    };
  },
  created() { },
  methods: {
    parseTime,
    beforeInit() {
      this.url = "api/personal/awardInfo";
      this.sort = ["id,desc"];
      return true;
    },
    onClickLeft() { },
    doAdd() {
      this.$router.push({ path: "/xs/a02/form" });
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/a02/detail",
        query: data,
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/xs/a02/form",
        query: data,
      });
    },
    // 检索
    onSearch() {
      console.log("2321321");
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          del([data.id]).then((res) => {
            this.$notify({
              type: "success",
              message: "操作成功",
              duration: 2000,
            });
            this.init();
            this.$set(data, "delLoading", false);
          });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    stuDisableBtn(spzt, type) {
      let status = parseInt(spzt);
      let success = [9];
      let error = [101, 201, 301, 401, 501, 601];
      let info = [0, 1, 2, 3, 4, 5, 6];
      let warning = [108, 208, 308, 408, 508, 608];
      if (type === "del") {
        if (success.indexOf(status) > -1) {
          return true;
        }
      } else if (type === "edit") {
        if (success.indexOf(status) > -1) {
          return true;
        }
        if (error.indexOf(status) > -1) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/styles/student/award.scss';
.van-list {
  margin-bottom: 54px;
}
.van-cell__title {
  font-size: 16px;
  text-align: left;
  min-width: 70%;
}
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
#opt-style {
  .van-cell__title {
    min-width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>
